import { styled, useTheme } from '@mui/material/styles';

const SkeletonText = styled('div')(
  ({
    color,
    height,
    widthPercent,
    marginBottom,
  }: {
    color: string;
    height: number;
    widthPercent: string;
    marginBottom: number;
  }) => ({
    backgroundColor: color,
    borderRadius: height / 2,
    width: widthPercent,
    marginBottom: marginBottom,
    height: height,
  }),
);

interface TextSkeletonProps {
  height?: number;
  color?: string;
  widthPercent?: string;
  marginBottom?: number;
}

export const TextSkeleton = (props: TextSkeletonProps) => {
  const theme = useTheme();
  const defaultColor = theme.palette.grey[300];
  const {
    height = 16,
    color = defaultColor,
    widthPercent = '100%',
    marginBottom = 8,
  } = props;

  return (
    <SkeletonText
      height={height}
      color={color}
      widthPercent={widthPercent}
      marginBottom={marginBottom}
    />
  );
};
