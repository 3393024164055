import { styled } from '@mui/material/styles';

import { getTwoThirdsLegalText } from 'lib/legaltext/legalTextHelper';
import { useBestOffer } from 'contexts/BestOfferContext';
import { useLoanSelection } from 'contexts/LoanSelectionContext';
import { TextSkeleton } from 'components/Skeletons/TextSkeleton';

const LEGAL_TEXT_FONT_SIZE = 14;

const LegalTextWrapper = styled('p')(({ theme }) => ({
  margin: 0,
  marginBottom: 36,
  marginTop: 16,
  fontSize: LEGAL_TEXT_FONT_SIZE,
  lineHeight: 1.33333,
  color: theme.palette.grey[800],
  width: '100%',

  [theme.breakpoints.up('md')]: {
    marginBottom: 36,
    paddingRight: 16,
    width: '50%',
  },
}));

const SkeletonTextWrapper = styled('div')(({ theme }) => ({
  margin: 0,
  marginBottom: 36,
  marginTop: 16,
  lineHeight: 1.33333,
  width: '100%',

  [theme.breakpoints.up('md')]: {
    marginBottom: 36,
    paddingRight: 16,
    width: '50%',
  },
}));

// Note: this is currently the largest contentful paint on the page,
// closely followed by the cookie banner text. Optimizing it will have no big
// impact on the overall performance of the page, unless we are also getting
// rid of the cookie banner, in which case we have the chance of moving the LCP
// to an earlier point in time.
// Adding a skeleton unfortunately does not help, as google detects that it is
// not the real text and ignores it for the LCP calculation.
export const LegalText = () => {
  const { loanSelection } = useLoanSelection();
  const { bestOffer } = useBestOffer();

  const text = getTwoThirdsLegalText(bestOffer, loanSelection);

  return (
    <>
      {text ? (
        <LegalTextWrapper>{text && <>¹{text}</>}</LegalTextWrapper>
      ) : (
        <SkeletonTextWrapper>
          <TextSkeleton height={LEGAL_TEXT_FONT_SIZE} marginBottom={6} />
          <TextSkeleton height={LEGAL_TEXT_FONT_SIZE} marginBottom={6} />
          <TextSkeleton height={LEGAL_TEXT_FONT_SIZE} marginBottom={6} />
          <TextSkeleton
            height={LEGAL_TEXT_FONT_SIZE}
            widthPercent="75%"
            marginBottom={6}
          />
        </SkeletonTextWrapper>
      )}
    </>
  );
};
